import React, { useEffect, useState } from "react";
import { Context as HomeContext } from "../../context/HomeContext";
import HeaderComp from "../../components/home/headerComp";
import FooterComp from "../../components/home/footerComp";
import Services from "./Blocks/Services";
import Dot from "../../components/home/Dot";
import emailjs from "emailjs-com";
import { init } from "emailjs-com";
import Story from "./Blocks/Story";
import Testimonial from "./Blocks/Testimonial";
import Blog from "./Blocks/Blog";
import Platform from "./Blocks/Platform";
import Contact from "./Blocks/Contact";
import Portfolio from "./Blocks/Portfolio";
import Expertise from "./Blocks/Expertise";
import About from "./Blocks/About";
import Intro from "./Blocks/Intro";
import Approximate from "./Blocks/Approximate";



init("user_XrszvfDP8vesaRJjFlN2H");

const Home = () => {
  const { state, landingPage } = React.useContext(HomeContext);
  const [formSubmit, setFormSubmit] = useState(0);
  // useEffect(() => {
  //   landingPage();
  // }, []);
  // console.log(state.homepageData ? state.homepageData.status : null);
  // console.log(Email);
  

  return (
    <div>
      <div id="pageWrapper">
        {/* phStickyWrap */}
        <div className="phStickyWrap">
          <HeaderComp />
        </div>
        <main>
          {/* introBlock */}
          <Intro />
          
            {/* storyBlock */}
            <Story/>
          {/* <Dot
            positon="3"
            timings="2.3s"
            size="sm"
            style1="d-md-block d-sm-none"
          /> */}
          {/* ibUpperWrap */}
          <div class="archik-ibUpperWrap">
            {/* aboutBlock */}
            <About />
           
            {/* servicesBlock */}
            <Services />
            {/* expertiseBlock */}
            <Expertise />
            {/* portfolioBlock */}
            <Portfolio />
            <Approximate/>


            {/* testimonialsBlock */}
            <Testimonial />
            {/* newsBlogBlock */}
            {/* <Blog/> */}
            {/* platformBlock */}
            <Platform />
          </div>
        </main>
        {/* ftAreaWrap */}
        <div className="ftAreaWrap position-relative">
          {/* footerAside */}
          <Contact  formStatus={formSubmit} />
          {/* textCopyright */}

          <FooterComp />
        </div>
      </div>
      <div className="bg-seconday textCopyright text-center pt-3">
        <p className="archik-content">
          <strong className="fwMedium">
            © <a href="#"><strong>Powered by Fusion Minds technologies and Archik Technologies</strong></a> 2023 |{" "}
            <span className="text-white">All Rights Reserved</span>
          </strong>
        </p>
      </div>
    </div>
  );
};

export default Home;
