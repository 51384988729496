import React from "react";
import Assets from "../Assets";

function footerComp(props) {
  return (
    <footer
      id="pageFooter"
      className="bg-secondary pt-3 pb-1 position-relative text-center text-md-left wow fadeIn archik-footer-bg"
    >
      <div className="container">
        {/* ftNav */}
        <nav className="ftNav">
          <ul className="list-unstyled d-flex justify-content-center justify-content-md-start  font-weight-normal text-uppercase mb-0 archik-footer-link">
            <li>
              <a href="/#pageWrapper" className="smooth text-white">
                Home
              </a>
            </li>
            <li>
              <a href="/#about" className="smooth text-white">
                About
              </a>
            </li>
            <li>
              <a href="/#services" className="smooth text-white">
                Services
              </a>
            </li>
            <li>
              <a href="/#works" className="smooth text-white">
                Works
              </a>
            </li>
            <li>
              <a href="/#contact" className="smooth text-white">
                Contact
              </a>
            </li>
            <li>
              <a href="/digital-marketing" className="smooth text-white">
                Digital Marketing
              </a>
            </li>
          </ul>
        </nav>
      </div>
      {/* btnTop */}
      {props.dis !== false && (
        <a
          href="#pageWrapper"
          className="btnTop smooth d-none d-md-flex align-items-center justify-content-center  text-white position-absolute rounded-circle bg-light"
        >
          <img src={Assets.NAVIGATION_UP} alt="Back to Top" />
        </a>
      )}

      {/* <div id="loader" className="loader-holder">
        <div className="lds-ellipsis">
          <div />
          <div />
          <div />
          <div />
        </div>
      </div> */}

      {/* textCopyright */}
    </footer>
  );
}

export default footerComp;
