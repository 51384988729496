const Assets = {
  LOGO: "../assets/images/logo_transparent_2.png",
  CONTAINER_BACKGROUND: "../assets/images/container.png",
  MOBILE_BANNER: "../assets/images/mobilebanner.jpg",
  ABOUTUS_CONTAINER: "../assets/images/aboutus.png",
  SERVICES_E2E: "../assets/images/e2e.png",
  SERVICES_DEV: "../assets/images/development.png",
  SERVICES_RISK: "../assets/images/risk.png",
  SERVICES_SEO: "../assets/images/seo.png",
  RECTANGLE_IMAGE: "../assets/images/Rectangle33.png",
  TESTIMONIAL_IMAGE: "../assets/images/Rectangle56.png",
  TESTIMONIAL_PROFILE: "../assets/images/companies2/testimonial-default@2x.png",
  TESTIMONIAL_QUOTES: "../assets/images/Group 61@2x.png",
  FACEBOOK_ICON: "../assets/images/fb@2x.png",
  FB_PLATFORM_ICON: "../assets/images/fb-p.png",
  TWEET_PLATFORM_ICON: "../assets/images/icon/twitter.png",
  LINKEDIN_PLATFORM_ICON: "../assets/images/li-p.png",
  TWITTER_ICON: "../assets/images/twitter@2x.png",
  INSTAGRAM_ICON: "../assets/images/insta.png",
  LINKEDIN_ICON: "../assets/images/linkedin@2x.png",
  WHATSAPP_ICON: "../assets/images/wa.png",
  CALL_ICON: "../assets/images/call.png",
  EMAIL_ICON: "../assets/images/email.png",
  NAVIGATION_ICON: "../assets/images/navigation@2x.png",
  NAVIGATION_UP: "../assets/images/navup.png",
  LOGO_FAVICON: "../assets/images/log-favicon.png",
  THANK_YOU: "../assets/images/thankyou1.png",
  WORK_AAIR: "../assets/images/companies2/aair-main@2x.png",
  WORK_NORIAPAY: "../assets/images/companies2/noriapay@2x.png",
  WORK_YOGA: "../assets/images/companies2/yogavignana@2x.png",
  TESTIMONIAL_MAIN_APTIVEGLOBE:
    "../assets/images/companies2/AptiveGlobe-Logo@2x.png",
  ZELISH_TESTIMONIAL: "../assets/images/companies2/zelish-testimonial@2x.png",
  TESTIMONIAL_SANJEEV: "../assets/images/icon/sanjeev-rounded@2x.png",
  TESTIMONIAL_ADARSH: "../assets/images/icon/icons2/adarsh.png",
  APTIVEGLOBE_GRAY: "../assets/images/icon/icons2/AptiveGlobe-gray-Logo.jpg",
  APTIVEGLOBE_MAIN: "../assets/images/icon/icons2/AptiveGlobe-Logo.jpg",
  ENSEMBLE_MAIN: "../assets/images/companies2/ensemble-testimonial@2x.png",
  HAVELLS_ADONIA_APP: "../assets/images/companies2/havellsadonia@2x.png",
  HAVELLS_SMART_APP: "../assets/images/companies2/havellssmart@2x.png",
  HAVELLS_SOLAR_APP: "../assets/images/companies2/havellssolar@2x.png",
  // HIBBET_APP:"../assets/images/companies/hibbet@2x.png",
  APPBEE_WEB: "../assets/images/companies2/appbee-main@2x.png",
  MY_GATE_APP: "../assets/images/companies2/mygate@2x.png",
  YOGASUTRA_APP: "../assets/images/companies2/yogasutra-main@2x.png",
  PHLEBO_APP: "../assets/images/companies2/phlebo@2x.png",
  SATTVACHINTANAM: "../assets/images/companies2/satvachintanam-main@2x.png",
  TASKR_WEB: "../assets/images/companies2/taskr@2x.png",
  MAGIK_WEB: "../assets/images/companies2/magik@2x.png",
  BBNL_WEB: "../assets/images/companies2/bbnl@2x.png",
  CHIMPMONK_WEB: "../assets/images/companies2/chimpmonk@2x.png",
  ZELISH_WEB: "../assets/images/companies2/zelish@2x.png",
  OTT_WEB: "../assets/images/companies2/kannadaott@2x.png",
  BROCHURE_PDF: "../assets/brochure.pdf",
  DM_LOGO: "../assets/images/dm-logo.svg",
  DM_DESIGN: "../assets/images/dm-design.svg",
};
export default Assets;
