import React from 'react';

function Blog(props) {
    return (
        <section className="newsBlogBlock  pt-7 pb-4 pt-md-10 pb-md-7 pt-lg-16 pb-lg-13 pt-xl-23 pb-xl-18">
              <div id="blog" className="mt-n43 pt-43">
                <div className="container">
                  <header className="text-center mb-7 mb-lg-9 mb-lg-13">
                    <h2 className="text-capitalize">
                      {/* headingTitle */}
                      <strong className="fwMedium d-inline-block align-top text-white position-relative headingTitle text-capitalize mb-3 wow fadeInLeft">News &amp; Blog</strong>
                      <span className="d-block wow fadeInUp" data-wow-delay="0.7s">Some Tips &amp; Activity</span>
                    </h2>
                  </header>
                  <div className="wow fadeInDown" data-wow-delay="1.2s">
                    {/* isoContentHolder */}
                    <div className="isoContentHolder row">
                      <div className="isoCol col-12 col-sm-6 col-md-4">
                        {/* nwbColumn */}
                        <article className="w-100 nwbColumn heightLg rounded-lg position-relative overflow-hidden mb-6">
                          <span className="bgCover imgHolder position-absolute rounded-lg w-100 h-100" style={{backgroundImage: 'url(http://htmlbeans.com/images/img11.jpg)'}} />
                          {/* nwbcCaption */}
                          <div className="nwbcCaption hasShadowBottom d-flex align-items-end w-100 h-100 position-absolute">
                            <div className="w-100 py-4 px-4 py-xl-7 px-xl-8">
                              <time dateTime="2011-01-12" className="d-block font-weight-normal timeStamp mb-3">18 Sep, 2020 - Design</time>
                              <h3 className="text-white nwbcHeading font-weight-normal mb-0">
                                <a href="=&quot;javascript:void(0);&quot;">The Shocking Revelation of Portfolio</a>
                              </h3>
                            </div>
                          </div>
                        </article>
                      </div>
                      <div className="isoCol col-12 col-sm-6 col-md-4">
                        {/* nwbColumn */}
                        <article className="w-100 nwbColumn rounded-lg position-relative overflow-hidden mb-6">
                          <span className="bgCover imgHolder position-absolute rounded-lg w-100 h-100" style={{backgroundImage: 'url(http://htmlbeans.com/images/img12.jpg)'}} />
                          {/* nwbcCaption */}
                          <div className="nwbcCaption d-flex align-items-end w-100 h-100 position-absolute">
                            <div className="w-100 py-4 px-4 py-xl-7 px-xl-8">
                              <time dateTime="2011-01-12" className="d-block font-weight-normal timeStamp mb-3">18 Sep, 2020 - Design</time>
                              <h3 className="text-white nwbcHeading font-weight-normal mb-0">
                                <a href="=&quot;javascript:void(0);&quot;">The Shocking Revelation of Portfolio</a>
                              </h3>
                            </div>
                          </div>
                        </article>
                      </div>
                      <div className="isoCol col-12 col-sm-6 col-md-4">
                        {/* nwbColumn */}
                        <article className="w-100 nwbColumn heightLg rounded-lg position-relative overflow-hidden mb-6">
                          <span className="bgCover imgHolder position-absolute rounded-lg w-100 h-100" style={{backgroundImage: 'url(http://htmlbeans.com/images/img14.jpg)'}} />
                          {/* nwbcCaption */}
                          <div className="nwbcCaption d-flex align-items-end w-100 h-100 position-absolute">
                            <div className="w-100 py-4 px-4 py-xl-7 px-xl-8">
                              <time dateTime="2011-01-12" className="d-block font-weight-normal timeStamp mb-3">18 Sep, 2020 - Design</time>
                              <h3 className="text-white nwbcHeading font-weight-normal mb-0">
                                <a href="=&quot;javascript:void(0);&quot;">The Shocking Revelation of Portfolio</a>
                              </h3>
                            </div>
                          </div>
                        </article>
                      </div>
                      <div className="isoCol col-12 col-sm-6 col-md-4">
                        {/* nwbColumn */}
                        <article className="w-100 nwbColumn rounded-lg position-relative overflow-hidden mb-6">
                          <span className="bgCover imgHolder position-absolute rounded-lg w-100 h-100" style={{backgroundImage: 'url(http://htmlbeans.com/images/img13.jpg)'}} />
                          {/* nwbcCaption */}
                          <div className="nwbcCaption d-flex align-items-end w-100 h-100 position-absolute">
                            <div className="w-100 py-4 px-4 py-xl-7 px-xl-8">
                              <time dateTime="2011-01-12" className="d-block font-weight-normal timeStamp mb-3">18 Sep, 2020 - Design</time>
                              <h3 className="text-white nwbcHeading font-weight-normal mb-0">
                                <a href="=&quot;javascript:void(0);&quot;">The Shocking Revelation of Portfolio</a>
                              </h3>
                            </div>
                          </div>
                        </article>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
    );
}

export default Blog;