import React from 'react';
import Dot from "../../../components/home/Dot";
import Assets from '../../../components/Assets';

function About(props) {
    return (
        <div className="container-fluid bg-archik archik-aboutBlock">
        <article className="aboutBlock pt-7 pb-7 pt-md-13 pb-md-13 pt-lg-18 pb-lg-18 pt-xl-23 pb-xl-24 ">
          <div id="about" className="mt-n43 pt-43">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-6 order-md-2">
                  <header className="mb-lg-5">
                    <h2 className="text-capitalize mb-4 mb-lg-7">
                      <strong className="fwMedium d-block text-white position-relative headingTitle text-capitalize mb-5 wow fadeInRight archik-sub-heading archik-sub-headTitle">ABOUT US</strong>
                      <span className="d-block wow fadeInRight archik-intro-headTitle" data-wow-delay="0.7s">Who We Are</span>
                    </h2>                   
                    <h3 className="text-white fwMedium font-weight-light wow mb-7 fadeInRight archik-sub-heading" data-wow-delay="0.9s">We are a one stop solution for all your businesses. With an expertise of more than 20 man years, we help ease your day-to-day process through our technology driven solutions in spectrum of various domains.</h3>
                  </header>
                  {/* <p className="wow fadeInRight" data-wow-delay="0.7s">Lorem Ipsum is simply dummy text of the printing  typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the  simply dummy text 1500s.</p> */}
                  {/* btn */}
                  <a href={Assets.BROCHURE_PDF} download="Archik Technologies Brochure"className="btn btnThemeOutline position-relative btnMinSmall mt-3 mt-lg-5 wow bounceIn archik-intro-btn1" data-hover="DOWNLOAD BROCHURE" data-wow-delay="2.3s"><span className="d-block btnText archik-intro-btn1-text">DOWNLOAD BROCHURE</span></a>
                </div>
                <div className="col-12 col-md-6 order-md-1 mt-xs-20">
                  <div className="abFeaturesWrap mx-auto mx-md-0 position-relative">
                    <ul className="list-unstyled abFeatureList text-center d-flex justify-content-center justify-content-sm-start flex-wrap pt-sm-20 pt-15 pt-sm-10 pt-xs-20 mb-n8 mb-sm-3">
                      <AboutUs type="Products Delivered" count="56+" delay="1s"/>
                      <AboutUs type="&nbsp;Happy&nbsp; Clients " count="30+" delay="1.3s"/>
                      <AboutUs type="Projects In Progress" count="15+" delay="1.7s"/>
                      <AboutUs type="Working Hours" count="36k+" delay="2s"/>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
        </div>
    );
}

function AboutUs({type,count,delay}){
  return  <li>
  <div className="abflColumn rounded-circle bg-dark text-white d-flex align-items-center justify-content-center position-relative mx-auto mx-sm-0 wow bounceIn" data-wow-delay={delay}>
    <strong className="abflcTag position-absolute fwMedium text-white rounded-circle d-flex align-items-center justify-content-center wow bounceIn" data-wow-delay={delay}>{count}</strong>
    <h4 className="abflcHeading text-white fwMedium archik-sub-heading">{type}</h4>
  </div>
</li>
}

export default About;