import React from "react";
import logo from "./assets/logo.png";
import pattern1 from "./assets/pattern-1.png";
import "./digital-marketing.css";

const DigitalMarketing = () => {
  const growthMarketing = [
    {
      type: "Cost",
      growthMarketing: "Economical & Fast",
      traditionalMarketing: "Expensive & time consuming",
    },
    {
      type: "Visibility",
      growthMarketing: "Chances to go viral",
      traditionalMarketing: "Work at its own pace",
    },
    {
      type: "Measurable",
      growthMarketing: "Real time results",
      traditionalMarketing: "Tough real time metrics",
    },
    {
      type: "Availability",
      growthMarketing: "Continuos exposure",
      traditionalMarketing: "Timed exposure to a limited set of audience",
    },
    {
      type: "ROI",
      growthMarketing: "Quick measurable ROI",
      traditionalMarketing: "Time consuming process to understand ROI",
    },
  ];

  return (
    <div className="d-flex flex-column">
      <section className="d-flex flex-column digital-container blue-lg-bg section-1 ">
        <div className="d-flex justify-content-start w-100 ml-30 mt-n10">
          <img
            className="img-fluid"
            alt="Archik logo"
            style={{ width: "120px" }}
            src={logo}
          />
        </div>
        <h1 className="title">Digital</h1>
        <h1 className="title">Marketing</h1>
        <hr className="hr"></hr>
        <p className="title">
          we run a digital marketing agency that helps Ecommerce brands and
          early
        </p>
        <p className="title">
          stage startups improve their <strong>ROAS</strong> via online
          advertising.
        </p>
        <h3 className="title">How have we impacted businesses</h3>
        <div className="row investment-container">
          <div className="investment-card">
            <div className="investment-card-header text-center text-white">
              ROI
            </div>
            <div className="investment-card-body text-center text-white">
              5x via social media & 3x via Google Ads
            </div>
          </div>
          <div className="investment-card">
            <div className="investment-card-header text-center text-white">
              Conversion rates
            </div>
            <div className="investment-card-body text-center text-white">
              30% hike in conversion rates within in 60 days
            </div>
          </div>
        </div>
      </section>
      <section className="d-flex flex-column digital-container red-lg-bg">
        <div className="d-flex align-items-center mt-20">
          <img
            src={pattern1}
            className="pattern-service-provided"
            alt="pattern"
          />
          <h3 className="ml-40">SERVICES PROVIDED</h3>
          <div className="ml-20">
            <p>//hover reference</p>
            <div className="lead-generation">Lead Generation</div>
          </div>
        </div>
        <div className="m-5">
          <div className="w-100">
            <div className="d-flex justify-content-center w-100">
              <div className="grid-item">Lead generation</div>
              <div className="grid-item">Mobile app marketing</div>
              <div className="grid-item">Paid advertising</div>
              <div className="grid-item">SEO</div>
            </div>
            <div className="d-flex justify-content-center w-100">
              <div className="grid-item">Influencer marketing</div>
              <div className="grid-item">Organic marketing</div>
              <div className="grid-item">Community building and management</div>
            </div>
            <div className="d-flex justify-content-center w-100">
              <div className="grid-item">ORM/Crisis Management</div>
              <div className="grid-item">Content and Ad copywriting</div>
              <div className="grid-item">Social media strategies</div>
            </div>
            <div className="d-flex justify-content-center w-100">
              <div className="grid-item">
                App store / Playstore Optimization
              </div>
              <div className="grid-item">Google Analytics/Webmaster</div>
            </div>
          </div>
        </div>
        <img
          src={pattern1}
          className="pattern-service-provided-end"
          alt="pattern"
        />
      </section>
      <section className="d-flex flex-column digital-container justify-content-start bg-dark">
        <div className="w-100 bg-white p-3 m-0">
          <h3
            className="m-0 pl-10"
            style={{
              background: `-webkit-linear-gradient(90deg, rgb(169,2,35), rgb(72,2,89))`,
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            WHAT IS GROWTH MARKETING?
          </h3>
        </div>
        <p className="mt-10 w-100 text-center" style={{ fontSize: "18px" }}>
          Think of growth marketing as marketing 2.0. Growth marketing is
          intelligent, data-driven
        </p>
        <p className="mb-10 w-100 text-center" style={{ fontSize: "18px" }}>
          marketing for the purpose of adding revenue to the bottom line of your
          business
        </p>
        <h3>WHY CHOOSE GROWTH MARKETING?</h3>
        <div>
          <div className="row w-100 mt-5">
            <div className="col-lg-4"></div>
            <div className="col-lg-4 text-left">
              <h5>Growth Marketing</h5>
            </div>
            <div className="col-lg-4 text-center">
              <h5 className="ml-6">Traditional Marketing</h5>
            </div>
          </div>
          {growthMarketing.map((item, index) => {
            return (
              <div className="d-flex w-100 justify-content-center align-items-center">
                <div className="width text-right mr-5">{item.type}</div>
                <hr className="width hr-marketing"></hr>
                <div className="growth-marketing">{item.growthMarketing}</div>
                <hr className="width hr-marketing"></hr>
                <div className="width-traditional text-center ml-5">
                  {item.traditionalMarketing}
                </div>
              </div>
            );
          })}
        </div>
        <h3 className="m-7 mt-10">SERVICES PROVIDED</h3>
        <div>
          <div className="blue-lg-bg-0 p-5 mb-10">
            <img
              src={pattern1}
              className="pattern-service-provided-2"
              alt="pattern"
            />
            <div className="d-flex justify-content-center align-items-start w-100">
              <div className="grid-item">Performance Marketing</div>
              <div className="grid-item">Content Marketing</div>
              <div className="grid-item">Search Engine Optimization</div>
            </div>
            <div className="d-flex justify-content-center w-100">
              <div className="grid-item">Email Marketing</div>
              <div className="grid-item">Creative Design</div>
              <div className="grid-item">Brand awareness and positioning</div>
            </div>
            <div className="d-flex justify-content-center w-100">
              <div className="grid-item">Website development</div>
              <div className="grid-item">Programmatic Ads</div>
            </div>
          </div>
          <img
            src={pattern1}
            className="pattern-service-provided-end-2"
            alt="pattern"
          />
        </div>
      </section>
      <section className="d-flex flex-column digital-container bg-white justify-content-start">
        <div
          style={{ height: "80px", overflow: "hidden" }}
          className="d-flex red-lg-bg w-100 p-3 m-0 align-items-center justify-content-between"
        >
          <h3 className="m-0 pl-10">Marketing case studies</h3>
          <div className="d-flex" style={{ overflow: "hidden" }}>
            <img
              src={pattern1}
              style={{ width: "150px", height: "150px" }}
              alt="pattern"
            />
            <img
              src={pattern1}
              className="mt-10"
              style={{ width: "150px" }}
              alt="pattern"
            />
          </div>
        </div>
        <div className="w-100 d-flex align-items-start">
          <div className="red-lg-bg p-2 pl-5 pr-5 m-10 tabs-selected">
            <h5 className="m-0">Leading florist in the middle east</h5>
          </div>
          <div className="p-2 pl-5 pr-5 m-10 tabs-selected">
            <h5 className="m-0" style={{ color: "#BFBFBF" }}>
              Luxury shoe brand
            </h5>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DigitalMarketing;
