import React from "react";

function Expertise(props) {
  return (
    <article
      id="ctcont"
      className="expertiseBlock bg-archik  pt-7 pb-7 pt-md-10 pb-md-10 pt-lg-12 pb-lg-17 pt-xl-16 pb-xl-25"
    >
      <div className="container">
        <div className="row container">
          <div className="col-12 col-md-5">
            <header className="mb-5">
              <h2 className="text-capitalize mb-6">
                {/* headingTitle */}
                <strong className="fwMedium d-block text-white position-relative headingTitle text-uppercase mb-4 wow fadeInLeft archik-sub-heading archik-sub-headTitle">
                  <p>Expertise</p>
                </strong>
                <span
                  className="d-block text-light wow fadeInLeft archik-intro-headTitle"
                  data-wow-delay="0.5s"
                >
                  Domains and Technology stacks
                </span>
              </h2>
              <p
                className="text-white wow fadeInLeft archik-sub-heading2"
                data-wow-delay="0.9s"
              >
                Our skills comprises of fast-evolving technology.
              </p>
            </header>
            <p className="wow fadeInLeft archik-content" data-wow-delay="1.3s">
              Acknowledging the fact that the need of the hour is being up to
              date with the fast-changing technology, smart use of the latest
              technology stacks rather than reinventing the wheel. Our team’s
              primary moto is to keep ourselves well updated with the best of
              stacks in the industry
            </p>
            {/* btn */}
            <a
              href='#contact'
              className="btn btnThemeOutline position-relative btnMinSmall mt-5 wow bounceIn archik-intro-btn1"
              data-wow-delay="1.7s"
              data-hover="Hire Me"
            >
              <span className="d-block btnText archik-intro-btn1-text">
                ASSOCIATE WITH US
              </span>
            </a>
          </div>
          <div className="col-12 col-md-7">
            {/* extraWrap */}
            <div className="extraWrap mt-7 mt-md-0 pt-md-5 pl-md-14">
              {/* prgCapWrap */}
              <div className="prgCapWrap d-flex align-items-center position-relative mb-5 wow">
                <strong className="d-block flex-shrink-0 text-uppercase fwMedium prgTitle archik-sub-heading text-white">
                  Skillsets & Framework
                </strong>
                {/* progress */}
                <div className="progress w-100 position-relative">
                  <div
                    className="progress-bar archik-progress-bar"
                    role="progressbar"
                    style={{ width: "64%" }}
                    aria-valuenow={64}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
                {/* prgShowSpan */}
                <strong className="prgShowSpan rounded fwMedium archik-progress-bar text-white text-center d-block flex-shrink-0 position-relative ml-6 ">
                  60+
                </strong>
              </div>
              {/* prgCapWrap */}
              <div className="prgCapWrap d-flex align-items-center position-relative mb-5 wow">
                <strong className="d-block flex-shrink-0 text-uppercase fwMedium prgTitle archik-sub-heading text-white">
                  Domains
                </strong>
                {/* progress */}
                <div className="progress w-100 position-relative">
                  <div
                    className="progress-bar  archik-progress-bar"
                    role="progressbar"
                    style={{ width: "23%" }}
                    aria-valuenow={23}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
                {/* prgShowSpan */}
                <strong className="prgShowSpan rounded fwMedium  archik-progress-bar text-white text-center d-block flex-shrink-0 position-relative ml-6 ">
                  20+
                </strong>
              </div>
              <div className="prgCapWrap d-flex align-items-center position-relative mb-5 wow">
                <strong className="d-block flex-shrink-0 text-uppercase fwMedium prgTitle archik-sub-heading text-white">
                  Mobile Apps
                </strong>
                {/* progress */}
                <div className="progress w-100 position-relative">
                  <div
                    className="progress-bar  archik-progress-bar"
                    role="progressbar"
                    style={{ width: "23%" }}
                    aria-valuenow={23}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
                {/* prgShowSpan */}
                <strong className="prgShowSpan rounded fwMedium  archik-progress-bar text-white text-center d-block flex-shrink-0 position-relative ml-6 ">
                  20+
                </strong>
              </div>
              <div className="prgCapWrap d-flex align-items-center position-relative mb-5 wow">
                <strong className="d-block flex-shrink-0 text-uppercase fwMedium prgTitle archik-sub-heading text-white">
                  Brand Logos
                </strong>
                {/* progress */}
                <div className="progress w-100 position-relative">
                  <div
                    className="progress-bar  archik-progress-bar"
                    role="progressbar"
                    style={{ width: "12%" }}
                    aria-valuenow={10}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
                {/* prgShowSpan */}
                <strong className="prgShowSpan rounded fwMedium  archik-progress-bar text-white text-center d-block flex-shrink-0 position-relative ml-6 ">
                  10+
                </strong>
              </div>
              {/* <div className="prgCapWrap d-flex align-items-center position-relative mb-5 wow">
                <strong className="d-block flex-shrink-0 text-uppercase fwMedium prgTitle archik-sub-heading text-white">
                  Languages
                </strong>
                <div className="progress w-100 position-relative">
                  <div
                    className="progress-bar  archik-progress-bar"
                    role="progressbar"
                    style={{ width: "23%" }}
                    aria-valuenow={23}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
                <strong className="prgShowSpan rounded fwMedium  archik-progress-bar text-white text-center d-block flex-shrink-0 position-relative ml-6 dataCountBar">
                  23
                </strong>
              </div> */}
              {/* pieList */}
              <ul className="list-unstyled pieList d-flex justify-content-center flex-wrap mb-n6 mt-10 mt-md-11">
              <li className="wow" data-wow-delay="1s">
                  {/* pieColumn */}
                  <div className="pieColumn position-relative text-white text-center">
                    <strong
                      className="fwMedium position-absolute titleIn d-flex align-items-center justify-content-center "
                      data-count-start={0}
                    >
                      100k
                    </strong>

                    <svg width={100} height={100} className="align-top mb-4">
                      <circle
                        className="cr1"
                        r="47.5"
                        cx={50}
                        cy={50}
                        fill="none"
                        stroke="#19232b"
                        strokeWidth={5}
                      />
                      <circle
                        className="cr2"
                        r="47.5"
                        cx={50}
                        cy={50}
                        fill="none"
                        stroke="#790933"
                        strokeWidth={5}
                        strokeDasharray="90 10"
                      />
                    </svg>

                    <span className="fwMedium titleOut d-block text-uppercase">
                      lines of code
                    </span>
                    <span> per month</span>
                  </div>
                </li>
                <li className="wow" data-wow-delay="0.5s">
                  {/* pieColumn */}
                  <div className="pieColumn position-relative text-white text-center">
                    <strong
                      className="fwMedium position-absolute titleIn d-flex align-items-center justify-content-center dataCount"
                      data-count-start={0}
                      data-count-end={53}
                    >
                      50
                    </strong>
                    <svg width={100} height={100} className="align-top mb-4">
                      <circle
                        className="cr1"
                        r="47.5"
                        cx={50}
                        cy={50}
                        fill="none"
                        stroke="#19232b"
                        strokeWidth={5}
                      />
                      <circle
                        className="cr2"
                        r="47.5"
                        cx={50}
                        cy={50}
                        fill="none"
                        stroke="#790933"
                        strokeWidth={5}
                        strokeDasharray="160 140"
                      />
                    </svg>
                    <span className="fwMedium titleOut d-block text-uppercase">
                      UI/UX designed
                    </span>
                  </div>
                </li>
                <li className="wow" data-wow-delay="0.5s">
                  {/* pieColumn */}
                  <div className="pieColumn position-relative text-white text-center">
                    <strong
                      className="fwMedium position-absolute titleIn d-flex align-items-center justify-content-center "
                      // data-count-start={0}
                      // data-count-end={53}
                    >
                      25+
                    </strong>
                   
                    <svg width={100} height={100} className="align-top mb-4">
                      <circle
                        className="cr2"
                        r="47.5"
                        cx={50}
                        cy={50}
                        fill="none"
                        stroke="#19232b"
                        strokeWidth={5}
                      />
                      <circle
                        className="cr1"
                        r="47.5"
                        cx={50}
                        cy={50}
                        fill="none"
                        stroke="#790933"
                        strokeWidth={5}
                        strokeDasharray="100 193"
                      />
                    </svg>
                    <span className="fwMedium titleOut d-block text-uppercase">
                      product 
                    </span>
                    <span>upgradations</span>
                  </div>
                </li>
                <li className="wow" data-wow-delay="1s">
                  {/* pieColumn */}
                  <div className="pieColumn position-relative text-white text-center">
                    <strong
                      className="fwMedium position-absolute titleIn d-flex align-items-center justify-content-center "
                      data-count-start={0}
                    >
                      100+
                    </strong>

                    <svg width={100} height={100} className="align-top mb-4">
                      <circle
                        className="cr1"
                        r="47.5"
                        cx={50}
                        cy={50}
                        fill="none"
                        stroke="#19232b"
                        strokeWidth={5}
                      />
                      <circle
                        className="cr2"
                        r="47.5"
                        cx={50}
                        cy={50}
                        fill="none"
                        stroke="#790933"
                        strokeWidth={5}
                        strokeDasharray="90 10"
                      />
                    </svg>

                    <span className="fwMedium titleOut d-block text-uppercase">
                      innovative
                    </span>
                    <span>sessions</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}

export default Expertise;
