import React from "react";
import Assets from "../../../components/Assets";

function Portfolio(props) {
  return (
    <section className="portfolioBlock bg-dark pt-7 pb-3 pt-md-12 pb-md-7 pt-lg-17 pb-lg-12 pt-xl-23 pb-xl-18">
      <div id="works" className="mt-n43 pt-43">
        <div className="container">
          <div className="container">
            {/* filterHead */}
            <header className="filterHead mb-10">
              <div className="row align-items-md-end">
                <div className="col-12 col-lg-5">
                  <h2 className="text-capitalize">
                    {/* headingTitle */}
                    <strong className="fwMedium d-block text-white position-relative headingTitle text-uppercase mb-3 wow fadeInRight archik-sub-heading archik-sub-headTitle">
                      Our Work
                    </strong>
                    <span
                      className="d-block text-white wow fadeInUp archik-intro-headTitle"
                      data-wow-delay="0.5s"
                    >
                      Projects
                    </span>
                  </h2>
                </div>
                <div className="col-12 col-lg-7">
                  {/* filtersList */}
                  <ul
                    className="list-unstyled filtersList isoFiltersList d-flex flex-wrap justify-content-lg-end text-uppercase fwMedium text-white wow bounceInUp archik-our-work-nav"
                    data-wow-delay="1s"
                  >
                    <li className="active">
                      <a
                        href="#"
                        className="archik-our-work-txt practive"
                        style={{ width: "50px" }}
                      >
                        ALL
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        data-filter=".wbdesign"
                        className="archik-our-work-txt"
                      >
                        WEB DESIGN
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        data-filter=".bran"
                        className="archik-our-work-txt"
                      >
                        CARD
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        data-filter=".appdesign"
                        className="archik-our-work-txt"
                        style={{ width: "50px" }}
                      >
                        App
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </header>
            <div className="wow fadeInUp">
              {/* isoContentHolder */}
              {/*appdesign  wbdesign bran */}
              <div className="row isoContentHolder">
                <Wrapper
                  contentType="wbdesign"
                  project={Assets.WORK_AAIR}
                  projectTitle="AAIR"
                  projectContent="AAIR has specialized in more services area. Our full line of services is backed by decades of delivering quality workmanship and value to our customers."
                  LINK="https://www.aairservices.com/"
                />
                <Wrapper
                  contentType="wbdesign"
                  project={Assets.WORK_NORIAPAY}
                  projectTitle="NORIAPAY"
                  projectContent="Buy & Sell Crypto Currencies With Your Local Currency"
                  LINK="https://noriapay.com/"
                />
                <Wrapper
                  contentType="wbdesign"
                  project={Assets.WORK_YOGA}
                  projectTitle="YOGAVIGNANA"
                  projectContent="to provide scientific temperament to understand the inherit unity between the body, mind, and consciousness."
                  LINK="https://yogavijnana.in/"
                />
                <Wrapper
                  contentType="wbdesign"
                  project={Assets.ZELISH_WEB}
                  projectTitle="ZELISH"
                  projectContent="Zelish does all the planning and helps with shopping and cooking!"
                  LINK="https://zelish.in/"
                />
                <Wrapper
                  contentType="wbdesign"
                  project={Assets.MY_GATE_APP}
                  projectTitle="MY GATE"
                  projectContent="Simplify everyday living for your housing society with the most advanced security and community management app."
                  LINK="https://mygate.com/"
                />
                <Wrapper
                  contentType="wbdesign"
                  project={Assets.BBNL_WEB}
                  projectTitle="BBNL "
                  projectContent="Technical partners for FOFI product at BBNL"
                  LINK="https://bbnl.co.in/"
                />
                <Wrapper
                  contentType="wbdesign"
                  project={Assets.TASKR_WEB}
                  projectTitle="TASKR"
                  projectContent="An application to automatically track job status of repair workshop"
                  LINK=""
                />

                <Wrapper
                  contentType="appdesign"
                  project={Assets.HAVELLS_ADONIA_APP}
                  projectTitle="Havells Adonia"
                  projectContent="Havells 'Adonia I' WiFi is a next generation IOT enabled Smart Geyser."
                  LINK="https://play.google.com/store/apps/details?id=com.havells.geyser"
                />
                <Wrapper
                  contentType="appdesign"
                  project={Assets.PHLEBO_APP}
                  projectTitle="Phlebo App"
                  projectContent="THE APP FOR Dr LAL PATHLABS PHLEBOTOMISTS ONLY"
                  LINK="https://play.google.com/store/apps/details?id=com.lalpathlabs.phlebo&hl=en_IN&gl=US"
                />
                <Wrapper
                  contentType="appdesign"
                  project={Assets.HAVELLS_SMART_APP}
                  projectTitle="Havells Smart"
                  projectContent="Havells Smart is an ecosystem of connected products offered by Havells which can be controlled via phones using Havells Smart Mobile Application "
                  LINK="https://play.google.com/store/apps/details?id=com.havells.havellssmart"
                />

                <Wrapper
                  contentType="wbdesign"
                  project={Assets.CHIMPMONK_WEB}
                  projectTitle="CHIPMONK TECHNOLOGIES"
                  projectContent="CHIPMONK TECHNOLOGIES"
                  LINK="https://www.chipmonk.in/"
                />
                <Wrapper
                  contentType="appdesign"
                  project={Assets.HAVELLS_SOLAR_APP}
                  projectTitle="Havells Solar Enviro"
                  projectContent="Havells solar street light is a industry leading product bringing the eco energy / renewable energy and smart street lighting concepts together."
                  LINK="https://play.google.com/store/apps/details?id=in.chipmonk.havellsolar"
                />
                <Wrapper
                  contentType="wbdesign"
                  project={Assets.MAGIK_WEB}
                  projectTitle="Magik"
                  projectContent="An application to automatically track job status of repair workshop"
                  LINK="https://magik.archik.in"
                />

                <Wrapper
                  contentType="wbdesign"
                  project={Assets.APPBEE_WEB}
                  projectTitle="APPBEE"
                  projectContent="APPBEE"
                  LINK="http://www.appbee.in/"
                />
                <Wrapper
                  contentType="wbdesign"
                  project={Assets.OTT_WEB}
                  projectTitle="KANNADA OTT PLATFORM"
                  projectContent="KANNADA OTT PLATFORM"
                  LINK=""
                />
                <Wrapper
                  contentType="bran"
                  project={Assets.YOGASUTRA_APP}
                  projectTitle="Yogasutra App"
                  projectContent=""
                  LINK=""
                />
                <Wrapper
                  contentType="bran"
                  project={Assets.SATTVACHINTANAM}
                  projectTitle="SATTVACHINTANAM"
                  projectContent="An application for various scriptural feeds, meanings and regular learning channel"
                  LINK=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function Wrapper({ project, projectTitle, LINK, projectContent, contentType }) {
  return (
    <div className={`col-12 col-sm-6 col-lg-4 ${contentType} isoCol`}>
      <article className=" position-relative mcwColumn text-center mb-6">
        <div className="imgHolder">
          <img src={project} className="img-fluid" alt="image description" />
        </div>

        <div className="mcwcCaption text-white position-absolute d-flex align-items-center ">
          <div className="mcwccWrap   w-100 p-3">
            <a href={LINK} alt={projectTitle}>
              <h3 className="text-white text-capitalize fwMedium title">
                {projectTitle}
              </h3>
            </a>

            <h4
              className="text-white fwMedium subTitle"
              style={{ fontSize: "13px" }}
            >
              {projectContent}
            </h4>
          </div>
        </div>
      </article>
    </div>
  );
}
export default Portfolio;
