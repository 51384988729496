import React from "react";
import ReactPlayer from "react-player";

function Story(props) {
  return (
    <section>
      <article className="storyBlock bg-dark pt-7 pb-3 pt-md-12 pb-md-7 pt-lg-17 pb-lg-13 pt-xl-23 pb-xl-20">
        <header className="text-center mb-15">
          <h2 className="text-capitalize">
            {/* headingTitle */}
            {/* <strong className="fwMedium d-inline-block align-top text-white position-relative headingTitle text-capitalize mb-3 wow fadeInRight">Employment &amp; Education</strong> */}
            <span className="d-block wow bounceInUp" data-wow-delay="0.6s">
              The world famous Mysuru Dasara
            </span>
          </h2>
        </header>
        {/* <center><strong className="fwMedium d-inline-block align-top text-white position-relative headingTitle text-uppercase mb-3 wow fadeInRight archik-sub-heading archik-sub-headTitle">The world famous Mysuru Dasara</strong></center> */}

        <div id="journey" className="mt-n43 pt-43">
          <div className="row align-items-md-center">
            <div className="col-12 col-md-6">
              <div
                className=" mx-auto mx-md-0 rounded overflow-hidden mt-md-n4 mb-4 mb-md-0 wow fadeInLeft"
                data-wow-delay="0.5s"
              >
                <div className="container">
                  <span
                    className="d-block wow bounceInUp font-weight-500"
                    data-wow-delay="0.6s"
                    style={{ fontWeight: 500, color: "white" }}
                  >
                    {" "}
                    Dasara 2021 Live stream
                  </span>
                  {/* <ReactHlsPlayer
                    src="https://www.youtube.com/watch?v=SqEYU1mly48"
                    autoPlay={false}
                    controls={true}
                    width="100%"
                    height="auto"
                  /> */}
                  {/* <ReactPlayer url='https://www.youtube.com/watch?v=SqEYU1mly48'     width="100%"
                    style={{height:"100%"}} /> */}
                  <iframe width="100%" height="400" src="https://ai.invideo.io/watch/mPcDNJ_fiKD" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              {/* extraWrap */}
              <div className="extraWrap pl-5 pl-xl-0 ml-xl-n4">
                {/* experienceList */}
                <ul className="list-unstyled experienceList position-relative pt-8 mb-0">
                  <li>
                    {/* elStoryColumn */}
                    <div
                      className="elStoryColumn position-relative wow bounceInUp"
                      data-wow-delay="1s"
                    >
                      {/* <span className="escIcnWrap d-flex align-items-center justify-content-center text-center rounded-circle position-absolute">
            <span className="w-100 d-block">
              <img src="http://htmlbeans.com/images/ico05.svg" className="img-fluid" width={31} height={28} alt="icon" />
            </span>
          </span> */}
                      <div className="descrWrap">
                        {/* <strong className="prTitle font-weight-normal d-block">Present - 2020</strong> */}
                        <h3 className="text-white escHeading font-weight-normal mb-4">
                          About Dasara
                        </h3>
                        <p>
                        Mysuru Dasara has been an attraction and an epitome of Karnataka’s heritage and culture for more than 500 years.

While its beauty brings people from far away lands to Mysuru, We take pride in providing LIVE streaming facilities with the help of Mysuru Dasara committee, NIC Karnataka while giving you as much insight possible about the history and details of “Naada Habba Mysuru Dasara”..{" "}
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    {/* elStoryColumn */}
                    <div
                      className="elStoryColumn position-relative wow bounceInUp"
                      data-wow-delay="1.6s"
                    >
                      {/* <span className="escIcnWrap d-flex align-items-center justify-content-center text-center rounded-circle position-absolute">
            <span className="w-100 d-block">
              <img src="http://htmlbeans.com/images/ico06.svg" className="img-fluid" width={35} height={29} alt="icon" />
            </span>
          </span> */}
                      <div className="descrWrap">
                        {/* <strong className="prTitle font-weight-normal d-block">2016 - 2018</strong> */}
                        <h3 className="text-white escHeading font-weight-normal mb-4">
                          IT Partners
                        </h3>
                        <p>
                          {" "}
                          FusionMinds and Archik have associated to provide smooth streaming facility where we have executed various joint executions like LIVE Transcoding, Live stage setup, Camera/Production setup, Website & Analytics development and Event integrations. Please dive into the 2021 Mysuru Dasara celebration and experience the culture through technology.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    {/* elStoryColumn */}
                    <div
                      className="elStoryColumn position-relative wow bounceInUp"
                      data-wow-delay="2s"
                    >
                      {/* <span className="escIcnWrap d-flex align-items-center justify-content-center text-center rounded-circle position-absolute">
            <span className="w-100 d-block">
              <img src="http://htmlbeans.com/images/ico07.svg" className="img-fluid" width={31} height={31} alt="icon" />
            </span>
          </span> */}
                      <div className="descrWrap">
                        {/* <strong className="prTitle font-weight-normal d-block">2013 - 2016</strong>
            <h3 className="text-white escHeading font-weight-normal mb-4">Lead UI Designer / Apple Inc.</h3> */}
                        <p>
                          We were fortunate to successfully execute the live
                          streaming technology, website, analysis and live video
                          transcoding for Mysuru Dasara 2021
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    {/* elStoryColumn */}
                    <div
                      className="elStoryColumn position-relative wow bounceInUp"
                      data-wow-delay="2.3s"
                    >
                      {/* <span className="escIcnWrap d-flex align-items-center justify-content-center text-center rounded-circle position-absolute">
            <span className="w-100 d-block">
              <img src="http://htmlbeans.com/images/ico08.svg" className="img-fluid" width={30} height={30} alt="icon" />
            </span>
          </span> */}
                      {/* <div className="descrWrap">
            <strong className="prTitle font-weight-normal d-block">2009 - 2013</strong>
            <h3 className="text-white escHeading font-weight-normal mb-4">BA Degree / London School of Arts</h3>
            <p>Aenean sollicitudin, lorem quis bibendum auctor, nisi elit sagittis sem nibh. Duis sed odio sit amet nibh</p>
          </div> */}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>
  );
}

export default Story;
