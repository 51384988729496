import React from "react";
import Assets from "../../../components/Assets";

function Testimonial(props) {
  return (
    <section className="testimonialsBlock bg-archik pt-7 pb-7 pt-md-11 pb-md-11 pt-lg-15 pb-lg-15 pt-xl-23 pb-xl-20">
      
      <div className="container">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-5">
              <header className="mb-7 mb-md-10 mb-xl-16">
                <h2 className="text-capitalize">
                  {/* headingTitle */}
                  <strong className="fwMedium d-block text-white position-relative headingTitle text-uppercase mb-4 wow fadeInLeft archik-sub-heading archik-sub-headTitle">
                    Testimonials
                  </strong>
                  <span
                    className="d-block wow fadeInLeft archik-intro-headTitle"
                    data-wow-delay="0.7s"
                  >
                    Happy Clients
                  </span>
                </h2>
              </header>

              {/* sponsorsList */}
              <ul className="list-unstyled sponsorsList d-flex flex-wrap mb-0 text-center text-md-left">
              <li className="wow bounceIn" data-wow-delay="1.2s">
                  <a href="https://www.ensembleindia.com/">
                    <img
                      src={Assets.ENSEMBLE_MAIN}
                      className="img-fluid"
                      alt="ensemble"
                    />
                  </a>
                </li>
                <li className="wow bounceIn" data-wow-delay="0.9s">
                  <a href="https://aptiveglobe.com/" target="_blank">
                    <img
                      src={Assets.TESTIMONIAL_MAIN_APTIVEGLOBE}
                      className="img-fluid"
                      alt="fantastic helsinki"
                    />
                  </a>
                </li>
                
                <li className="wow bounceIn" data-wow-delay="1.5s">
                  <a href="https://zelish.in/">
                    <img
                      src={Assets.ZELISH_TESTIMONIAL}
                      className="img-fluid"
                      alt="zelish"
                    />
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-12 col-md-7">
              <div className="wow fadeInRight" data-wow-delay="2s">
                {/* quotesSlider */}
                <div className="quotesSlider">
                  <ClientQuote
                    clientThumbnail={Assets.TESTIMONIAL_PROFILE}
                    clientFeedback="Archik is a team of young, humble, dynamic & well versed with the today's technology, its my great experience/opportunity working along
                    with the team Archik"
                    clientName="Prashanth"
                    clientRole="VARNA COMMUNICATIONS"
                  />
                  <ClientQuote
                    clientThumbnail={Assets.TESTIMONIAL_PROFILE}
                    clientFeedback="Archik Technologies are a young, vibrant and aspiring team that focuses every minute detailing be it even for the smallest of the issue....they take the atmost seriousness irrespective of the problem is big or small...THEY GIVE YOU A TIMELY SOLUTION. Period"
                    clientName="Sudhakar Shastry"
                    clientRole="ENSEMBLE INC PRODUCTIONS"
                  />
                  <ClientQuote
                    clientThumbnail={Assets.TESTIMONIAL_ADARSH}
                    clientFeedback="If no one can, the team at Archik Technologies surely can. Such an Amazing support during production releases and you can see the team at its fullest potential! You inspire us, long way to go!"
                    clientName="Adarsh Hegde"
                    clientRole="APTIVEGLOBE TECHNOLOGIES PVT. LTD."
                  />
                  <ClientQuote
                    clientThumbnail={Assets.TESTIMONIAL_SANJEEV}
                    clientFeedback="One of the most humble teams I have come across. They have ensured all our problem statements were precisely recorded and gave an apt solution. No doubt, they have the best post delivery services!"
                    clientName="Sanjeev"
                    clientRole="NAMMA WELLNESS"
                  />
                  <ClientQuote
                    clientThumbnail={Assets.TESTIMONIAL_PROFILE}
                    clientFeedback="We were looking at building a complex B2C mobile application and a recommendation engine. They took over the entire development like it was their own and put in great efforts to build a fantastic product. Impressed with their expertise and service quality"
                    clientName="Rakesh Edavalath"
                    clientRole="Zelish"
                  />
                   <ClientQuote
                    clientThumbnail={Assets.TESTIMONIAL_PROFILE}
                    clientFeedback="We have worked with Archik technologies on a few engagements. They have a solid, dedicated team of developers. They have a good mix of experienced developers and understand business requirements clearly. They have robust project management practices and managed to deliver on time even during the height of the covid pandemic. We have been very happy with Archik technologies thanks to their tech abilities and post delivery support."
                    clientName="Ram saravana"
                    clientRole="Co-Founder Emproto"
                  />
                   <ClientQuote
                    clientThumbnail={Assets.TESTIMONIAL_PROFILE}
                    clientFeedback="We have experienced the best services with Archik Technologies and they are our extended team in delivering amazing work products which exceeded our expectations. They have accurate understanding of business modules, follow very precise timelines and  deliver great solutions based on the requirements. The team has good communication and collaboration capabilities. Arhick has also helped us provide information that was very timely, targeted and genuinely helpful for our Target customers."
                    clientName="Yashwanth"
                    clientRole="Founder Fusion Minds Technologies Pvt LTD"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </section>
  );
}
const ldJson = {
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [{
    "@type": "ListItem",
    "position": 1,
    "name": "Blogs",
    "item": "https://www.speblog.org"
  },{
    "@type": "ListItem",
    "position": 2,
    "name": "Archik Technologies",
    "item": "https://www.archik.in"
  }]
};

<script type="application/ld+json">
  {JSON.stringify(ldJson)}
</script>
const ClientQuote = ({clientThumbnail, clientFeedback, clientName, clientRole }) => {
  return (
    <div>
      <blockquote className="clientQuote border-0 m-0 text-white position-relative px-0 pt-4 pt-md-8 font-weight-300">
        <img
          src={Assets.TESTIMONIAL_QUOTES}
          className="img-fluid float-right"
          alt="Nathaniel D.Costa"
          style={{ width: "40px" }}
        />
        <div className="imgHolder rounded-circle mb-7">
          <img
            src={clientThumbnail}
            className="rounded-circle img-fluid"
            alt="Nathaniel D.Costa"
          />
        </div>
        <q className="d-block mb-3 archik-content">{clientFeedback}</q>
        <cite
          className="fwMedium text-white font-weight-bold d-block my-5 text-uppercase archik-sub-heading2"
          style={{ fontWeight: "400!important", letterSpacing: "2px" }}
        >
          {clientName}
        </cite>
        <span
          className="fwMedium text-white font-size-100  d-block"
          style={{ fontSize: "14px" }}
        >
          {clientRole}
        </span>
      </blockquote>
    </div>
  );
};

export default Testimonial;
