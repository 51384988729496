import React from 'react';
import Assets from "../../../components/Assets";
import Dot from "../../../components/home/Dot";

function Approximate(props) {
    return (
        <section className="servicesBlock bg-dark pt-7 pt-md-13 pt-lg-19 pb-lg-13 pt-xl-24 pb-xl-19">
              <div id="services" className="mt-n43 pt-43">
                <div className="container">
                  <header className="text-center mb-14 mb-lg-20">
                    <h2 className="text-capitalize mb-4 mb-lg-7">
                      {/* headingTitle */}
                      <strong className="fwMedium d-inline-block align-top text-white position-relative headingTitle text-uppercase mb-3 wow fadeInRight archik-sub-heading archik-sub-headTitle">Approximate Cost(s)</strong>
                      <span className="d-block wow fadeInUp text-white archik-intro-headTitle" data-wow-delay="0.5s">Example Product(s) & Cost(s) excluding Add Ons and Maintenance</span>
                     
                    </h2>
                  </header>
                  <div className="row justify-content-center">
                    <OfferCard header="Multitenant Ecommerce Site" content="End to End ecommerce application enabled for SEO, Categorical product bifurcation, payment gateway integration and much more. Cost: 150000/- INR" imageSrc = {Assets.SERVICES_E2E}/>
                   
                    <OfferCard header="Ticketing Solutions" content="Event Ticket Management Software including QR & NFC based entry. Customizable Theatre, Event, Gym, Malls with paid, auto entry and coupon based entry facilities at no extra cost. Cost: 85000/- INR" imageSrc = {Assets.SERVICES_SEO}/>
                    <OfferCard header="Health Care & Home Sample Collection Applications" content="Hospital management system, Pharmacy to home, Home sample collection applications with customized addons. Cost: 400000/- INR" imageSrc = {Assets.SERVICES_DEV}/>
                    <OfferCard header="Product upgradations & Risk assessment" content="Upgrade your existing product to a latest technology stack and ensuring there is a zero downtime and a zero UX disruption Cost: Starting 50000/- INR" imageSrc = {Assets.SERVICES_RISK}/>
                  </div>
                </div>
              </div>
            
            </section>
    );
}
function OfferCard(props) {
  const {header,content,imageSrc} = props
   return (
       <div className="col-12 col-md-6 col-lg-4 col-xl-3 d-flex ml-5 mr-5 mb-13 archik-our-services-card">
           <article className="msColumn position-relative bg-dark rounded-lg pt-14 pb-1 px-6 w-100  text-white " data-wow-delay="1s">
           <span className="mscIcnWrap rounded-circle d-flex align-items-center text-center position-absolute wow bounceIn" data-wow-delay="1.3s">
               <span className="w-100">
               <img src={imageSrc} width={34} height={34} alt="icon" />
               </span>
           </span>
        
           <h3 className="h5 fwMedium text-capitalize text-white position-relative pb-5 mb-3 wow fadeInUp archik-sub-heading2" style={{height:"4em"}}>{header}</h3>
           <p className="wow fadeInUp archik-content">{content}</p>
           </article>
       </div>
   );
}

export default Approximate;